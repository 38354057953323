import { Container } from "./styles";

export default function Home() {
  return (
    <Container>
      <h1>"Tadinho do Diego, ter que aguentar o Nicolas."</h1>
      <span>Tia do Café 2023</span>
    </Container>
  );
}
