import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1 {
    width: 96%;
    max-width: 480px;
    color: var(--white);
  }

  span {
    width: 96%;
    max-width: 360px;
    font-style: italic;
    font-size: 0.875em;
    color: var(--white);
    text-align: right;
  }

  button {
    width: 10rem;
    height: 2rem;
    padding: 0 1rem;
    background-color: var(--orange);
    height: 2rem;
    border-radius: 0.25rem;
    border: 0;
    color: var(--background);
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.5rem;
    text-transform: uppercase;

    &[disabled] {
      background-color: var(--disabled);
    }
  }
`;
